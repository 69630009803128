import React from 'react';

const AboutUs: React.FunctionComponent = () => {
  return (
    <div
      id="about_us"
      className="m-14 flex w-3/5 flex-col gap-3 rounded-lg border-2 border-white bg-neutral-900 px-10 py-6 text-white sm:w-1/2 md:w-2/5"
    >
      <h1 className="text-base font-bold sm:text-xl md:text-2xl">
        Welcome to Security Dogs NI
      </h1>
      <p className="text-xs sm:text-base md:text-xl">
        Serving exclusively within Northern Ireland we are your local experts in
        K9 security, protection, and detection. With one of the largest group of
        handlers and K9's, we are dedicated to providing comprehensive services
        including event security, business premise, commercial and private
        contruction sites. Our aim is to ensure a safer environment for your
        business or event and we can fulfil this with the deployment of highly
        trained professional dog teams. We take pride in being associate members
        of NADSU and NTIPDU who keeps members up to date with the industry
        standards and codes of practice while adhering to British standards such
        as: BS 8517-1 and BS 85172-2.
      </p>
    </div>
  );
};

export default AboutUs;
