import React from 'react';
import Header from '@/components/Header';
import AboutUs from '@/components/AboutUs';
import ImageGallery from 'react-image-gallery';
import { galleryImages } from '@/gallery-images/const';
import ContactUs from '@/components/ContactUs';
import Footer from '@/components/Footer';
import InfoSection from './components/InfoSection';
import ReactGA from 'react-ga';
ReactGA.initialize('G-3EP9LRSV3R');

const App: React.FunctionComponent = () => {
  if (typeof window !== 'undefined') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div className="flex flex-col">
      <div
        className="mb-[10%] mt-0 flex h-[100vh] w-full flex-col"
        // style={{
        //   backgroundSize: '100%',
        //   backgroundImage:
        //     "url('https://cdn.midjourney.com/86962c49-4db9-4c54-a799-0fc61ca856d3/0_0.png')",
        // }}
      >
        <img
          src="https://cdn.midjourney.com/86962c49-4db9-4c54-a799-0fc61ca856d3/0_0.png"
          alt="Dog Background"
          className="absolute top-0 -z-10 w-screen"
        />
        <Header />
        <div className="flex justify-center md:justify-end">
          <AboutUs />
        </div>
      </div>
      <InfoSection />
      {/* <div className={styles["FAQ-section"]}>
        <FAQ />
      </div> */}
      <div className="mt-12 flex justify-center text-white">
        <div id="gallery" className="flex w-1/2 flex-col gap-5 text-center">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            Gallery
          </span>
          <ImageGallery
            items={galleryImages}
            showThumbnails={false}
            showPlayButton={false}
            showBullets
            lazyLoad
          />
        </div>
      </div>
      <div className="my-24 flex justify-center">
        <ContactUs />
      </div>
      <div className="flex justify-center">
        <Footer />
      </div>
    </div>
  );
};

export default App;
