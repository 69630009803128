import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Socials: React.FunctionComponent = () => {
  return (
    <div className="flex flex-row justify-between gap-2 [&>*]:cursor-pointer [&>*]:text-white [&>*]:no-underline">
      <a href="https://www.facebook.com/securedogsni">
        <FontAwesomeIcon
          icon={faFacebookSquare}
          color="#4267B2"
          className="h-6 w-6 sm:h-9 sm:w-9 md:h-12 md:w-12"
        />
      </a>
      <a href="mailto:john@securitysolutionsireland.ltd">
        <FontAwesomeIcon
          icon={faEnvelope}
          className="h-6 w-6 sm:h-9 sm:w-9 md:h-12 md:w-12"
        />
      </a>
    </div>
  );
};

export default Socials;
