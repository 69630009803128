import React from 'react';
import MAP from '@/images/map.png';
import SERVICES from '@/images/Services.jpg';
import TRAINING from '@/images/training.jpg';
import SECURITY from '@/images/security.jpg';
// import DETECTION_DOG from '@/images/detectiondog.png';

const InfoSection: React.FunctionComponent = () => {
  return (
    <div className="my-20 flex w-full flex-col gap-8 text-white sm:gap-12">
      <div className="flex w-full flex-col items-center gap-8 md:flex-row md:items-start md:justify-center">
        <div className="w-3/5 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 md:w-2/5">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            About Us
          </span>
          <p className="text-xs sm:text-base md:text-xl">
            You may have heard the saying "It's not the dog in the fight, but
            the fight in the dog." With years of experience in the security
            industry, we bring a personalized and efficient approach to
            safeguarding your assets without the bureaucratic hurdles of larger
            companies. Our management team is available 24/7 to address your
            needs, ensuring the utmost protection. Being a centrally located
            company, we have extensive links into the community giving us the
            ability to provide a robust service thoughout Northern Ireland
            yielding effective and realistic response times. Dogs are guards
            with a hightened sence of smell and hearing hundreds of times
            greater than a human, add the ability to search or track for an
            intruder using these senses in any area concealed or not. Key
            facilities around the UK use security dogs as they are proven to
            produce results.
          </p>
        </div>
        <img src={MAP} alt="Ballyclare map" className="w-4/5 md:w-2/5" />
      </div>
      <div className="flex w-full flex-col-reverse items-center gap-8 md:flex-row md:items-start md:justify-center">
        <img src={SERVICES} alt="Services" className="w-4/5 md:w-2/5" />
        <div className="w-3/5 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 md:w-2/5">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            Services
          </span>
          <div className="text-xs sm:text-base md:text-xl">
            <p>
              At Security Dogs NI, our security specialist dogs are at the
              forefront of safeguarding your premises and events. Trained to
              perfection, these exceptional canines possess a diverse skill set
              that includes tracking, article searching, and detainment. With an
              acute sense of smell, they expertly track and locate potential
              threats or intruders, enabling a proactive approach to security.
              Additionally, our dogs are adept at detainment, capable of
              subduing suspects while handlers make decisions. This powerful
              combination of tracking and detainment skills elevates our
              security specialist dogs to the highest standard of excellence,
              making them a vital asset in fortifying the safety and protection
              of your premises and events.
            </p>
            <p>
              Detection/Search dogs possess highly sensitive noses, they excel
              in detecting various substances, including a variety of drugs,
              ensuring a thorough screening process. These adept canines play a
              critical role in keeping businesses, events, and public spaces
              safe by swiftly identifying hidden contraband that may pose a
              threat to safety and security. Their meticulous article search
              capabilities aid in the discovery of concealed items, bolstering
              our commitment to comprehensive security measures. By deploying
              our skilled detection/search dogs, we ensure a proactive and
              effective approach to maintaining a secure environment, offering
              clients the confidence they need to carry out their activities
              with peace of mind.
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-8 md:flex-row md:items-start md:justify-center">
        <div className="w-3/5 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 md:w-2/5">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            Expert Training Philosophy
          </span>
          <p className="text-xs sm:text-base md:text-xl">
            Our dog training lies at the heart of our exceptional service
            delivery. We are committed to maintaining the highest standards of
            excellence in training our canine teams. Our dedicated trainers
            provide continuous education, honing the skills of our dogs to
            perform at their best in every situation. Furthermore, our handlers
            possess SIA licenses, emphasizing our commitment to maintaining a
            team of qualified and responsible professionals. Our rigorous
            training program also includes certifications from esteemed
            organizations such as NTIPDU ensuring that our dogs are proficient
            in all areas and follow the standards set by the National Tactical
            Police Dog Unit. The result is a team of highly skilled and reliable
            dogs, ready to meet the diverse needs of our clients and maintain an
            unparalleled level of security and protection.
          </p>
        </div>
        <img src={TRAINING} alt="Training dog" className="w-4/5 md:w-2/5" />
      </div>
      <div className="flex w-full flex-col-reverse items-center gap-8 md:flex-row md:items-start md:justify-center">
        <img src={SECURITY} alt="Security dogs" className="w-4/5 md:w-2/5" />
        <div className="w-3/5 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 md:w-2/5">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            Why use security dogs
          </span>
          <p className="text-xs sm:text-base md:text-xl">
            Employing security dogs for on-site protection offers unparalleled
            benefits that significantly enhance security measures. Trained to be
            an effective deterrent, these professional canine teams provide a
            robust and dynamic security solution. With their acute senses and
            exceptional agility, security dogs can swiftly detect and apprehend
            potential intruders or threats, mitigating risks before they
            escalate while their handlers make important decisions to control
            the situation. The presence of security dogs instills a sense of
            confidence and reassurance among clients, employees, and guests,
            while also significantly reducing the likelihood of criminal
            activities. Their ability to cover vast areas and respond to
            situations with precision makes them an indispensable asset in
            safeguarding businesses, events, and properties. When you choose
            Security Dogs NI, you opt for a proven and reliable security partner
            with experienced handlers and highly trained dogs dedicated to
            ensuring the utmost safety and protection for your premises.
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-8 md:flex-row md:items-start md:justify-center">
        <div className="w-3/5 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 md:w-2/5">
          <span className="text-base font-bold sm:text-xl md:text-2xl">
            Why opt for detection dogs
          </span>
          <p className="text-xs sm:text-base md:text-xl">
            These highly trained canines possess an extraordinary sense of
            smell, making them exceptionally adept at detecting even trace
            amounts of illicit substances or contraband. With their keen
            olfactory senses, detection dogs can swiftly and accurately identify
            hidden threats, enhancing security screening procedures and reducing
            the risk of potential harm. Their non-invasive approach also ensures
            a smooth flow of operations, minimizing disruptions while
            maintaining a secure environment. Security Dogs NI's detection teams
            are meticulously trained to handle various scenarios, from
            inspecting luggage and cargo to securing large events and public
            spaces. By employing detection dogs as part of your security
            services, you invest in a proactive defence strategy that offers
            comprehensive protection and peace of mind for your business and
            community.
          </p>
        </div>
        {/* <img
          src={DETECTION_DOG}
          alt="Detection dog"
          className="w-4/5 md:w-2/5"
        /> */}
      </div>
    </div>
  );
};

export default InfoSection;
