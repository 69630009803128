import one from './one.jpg';
import two from './two.jpg';
import three from './three.jpg';
import four from './four.jpg';
import five from './five.jpg';
import six from './six.jpg';
import seven from './seven.jpg';
import eight from './eight.jpg';
import nine from './nine.jpg';
import ten from './ten.jpg';

export const galleryImages = [
  { original: one },
  { original: two },
  { original: three },
  { original: four },
  { original: five },
  { original: six },
  { original: seven },
  { original: eight },
  { original: nine },
  { original: ten },
];
