import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const ContactUs: React.FunctionComponent = () => {
  const formRef = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async () => {
    const res = await emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID ?? 'no .env',
      process.env.REACT_APP_TEMPLATE_ID ?? 'no .env',
      formRef.current ?? '',
      process.env.REACT_APP_PUBLIC_KEY ?? 'no .env',
    );

    if (res.status === 200) {
      alert('Thanks for contacting us.  We aim aim to get in touch ASAP.');
      reset();
    } else {
      alert('Failed to send email.  We apologise for any convenience caused.');
      reset();
    }
  };

  return (
    <div
      id="contact_us"
      className="m:w-1/2 flex w-4/5 flex-col gap-4 rounded-lg border-2 border-white bg-neutral-900 px-10 py-5 text-white sm:gap-7 sm:px-20 sm:py-10 md:gap-10"
    >
      <div className="text-center text-base font-bold sm:text-xl">
        Contact Us
      </div>
      <div className="text-sm sm:text-base">
        Ready to take the next step in enhancing your security? Contact Security
        Dogs NI today. Let our expert canine teams design a tailored security
        strategy that ensures the safety and protection of your valuable assets,
        your business, and your community. With Security Dogs NI, you're not
        just a client – you're a valued partner in our unwavering commitment to
        create a safer and more secure Northern Ireland.
      </div>
      <form
        className="flex flex-col gap-5 [&>label]:flex [&>label]:flex-col [&>label]:gap-2 [&>p]:text-red-600"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <div className="flex w-full flex-col justify-stretch gap-5 sm:flex-row [&>label]:flex [&>label]:flex-row [&>label]:gap-2">
          <label className="w-full">
            First Name *:
            <input
              {...register('firstName', { required: true })}
              aria-invalid={errors.firstName ? 'true' : 'false'}
              className="h-6 text-black"
            />
            {errors.firstName?.type === 'required' && (
              <p role="alert" className="text-red-600">
                * First name is required
              </p>
            )}
          </label>
          <label className="w-full">
            Last Name *:
            <input
              {...register('lastName', { required: true })}
              aria-invalid={errors.lastName ? 'true' : 'false'}
              className="h-6 text-black"
            />
            {errors.lastName?.type === 'required' && (
              <p role="alert" className="text-red-600">
                * Last name is required
              </p>
            )}
          </label>
        </div>
        <label>
          Email *:
          <input
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
            type="email"
            aria-invalid={errors.email ? 'true' : 'false'}
            className="h-6 text-black"
          />
          {errors.email?.message && (
            <p role="alert" className="text-red-600">
              {errors.email?.message.toString()}
            </p>
          )}
          {errors.email?.type === 'required' && (
            <p role="alert" className="text-red-600">
              * Email is required
            </p>
          )}
        </label>
        <label>
          Phone Number:
          <input {...register('phoneNumber')} className="h-6 text-black" />
        </label>
        <label>
          Message *:
          <textarea
            {...register('message', { required: true })}
            aria-invalid={errors.message ? 'true' : 'false'}
            aria-multiline
            className="h-12 text-black sm:mt-2 sm:h-24 md:h-40"
          />
          {errors.message?.type === 'required' && (
            <p role="alert" className="text-red-600">
              * Message is required
            </p>
          )}
        </label>
        <input
          className="h-6 w-fit cursor-pointer self-center rounded border border-black bg-white px-2 text-black sm:h-8"
          type="submit"
          value="Submit"
        />
      </form>
    </div>
  );
};

export default ContactUs;
