import React from 'react';
import Socials from '@/components/Socials';
import NADSU from '@/images/nasdu-white.png';
import NTIPDU from '@/images/ntipdu.png';

const Footer: React.FunctionComponent = () => {
  return (
    <div className="flex w-full flex-col items-center gap-12 border-t-2 border-white bg-neutral-900 p-10 text-xs font-bold text-white sm:text-base">
      <div className="flex w-4/5 flex-col items-center justify-between gap-10 sm:flex-row">
        <div className="flex flex-col gap-5">
          <div>
            <p>Tel: 028 9303 7423</p>
            <p>
              Email:{' '}
              <a href="mailto:john@securitysolutionsireland.ltd">
                john@securitysolutionsireland.ltd
              </a>
            </p>
          </div>
          <div>
            <p>Ballyclare,</p>
            <p>Co. Antrim,</p>
            <p>Northern Ireland</p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-5">
          <a href="https://ntipdu.org/">
            <img
              src={NTIPDU}
              alt="SIA"
              className="h-12 cursor-pointer invert sm:h-16 md:h-24"
            />
          </a>
          <a href="https://nasdu.co.uk">
            <img
              src={NADSU}
              alt="NADSU"
              className="h-12 cursor-pointer sm:h-16 md:h-24"
            />
          </a>
        </div>
      </div>
      <span className="text-xs sm:text-base">
        Copyright © Security Dogs NI. All rights reserved.
      </span>
      <Socials />
    </div>
  );
};

export default Footer;
