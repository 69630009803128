import React from 'react';
import Socials from '@/components/Socials';

const Header: React.FunctionComponent = () => {
  return (
    <div className="mx-8 flex flex-row items-center justify-between md:mx-14">
      <div>
        <img
          className="w-16 sm:w-24 md:w-36"
          src="/working-dogs-logo.png"
          alt="Working Dogs"
        />
      </div>
      <div className="flex flex-row gap-3 text-center text-sm font-bold text-white sm:gap-6 md:gap-10 md:text-xl">
        <a className="hover:border-b-2" href="#about_us">
          About us
        </a>
        <a className="hover:border-b-2" href="#gallery">
          Gallery
        </a>
        <a className="hover:border-b-2" href="#contact_us">
          Contact us
        </a>
      </div>
      <Socials />
    </div>
  );
};

export default Header;
